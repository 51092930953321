const { AsciiChars, ExtendedAsciiChars } = require('../constants')

const allAsciiChars = AsciiChars.concat(ExtendedAsciiChars)
const allAsciiCharMap = allAsciiChars.reduce(function (accumulator, asciiChar) {
  accumulator[asciiChar['decimal']] = asciiChar
  return accumulator
}, {})

function getAsciiTable(start, end) {
  const charsetTable = []
  for (let i = start; i <= end; i++) {
    if (i in allAsciiCharMap) {
      const data = allAsciiCharMap[i]
      data['hex'] = Number(i).toString(16).toUpperCase().padStart(2, '0')
      data['octal'] = Number(i).toString(8).padStart(3, '0')
      data['binary'] = Number(i).toString(2).padStart(7, '0')

      charsetTable.push(data)
    } else {
      const data = {
        decimal: i,
        char: String.fromCharCode(i),
        hex: Number(i).toString(16).toUpperCase().padStart(2, '0'),
        octal: Number(i).toString(8).padStart(3, '0'),
        binary: Number(i).toString(2).padStart(7, '0'),
      }
      charsetTable.push(data)
    }
  }

  return charsetTable
}

exports.getAsciiTable = getAsciiTable
