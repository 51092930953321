import React from 'react'
import RehypeReact from 'rehype-react'
import Adsense from '../components/Adsense'
import Converter from '../components/Converter'

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    adsense: Adsense,
    converter: Converter,
  },
}).Compiler

export default renderAst
