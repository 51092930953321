const AsciiChars = [
  {
    decimal: 0,
    char: 'NUL',
    description: 'null character',
    url: 'null-character-NUL',
  },
  {
    decimal: 1,
    char: 'SOH',
    description: 'start of header',
    url: 'start-of-header-SOH',
  },
  {
    decimal: 2,
    char: 'STX',
    description: 'start of text',
    url: 'start-of-text-STX',
  },
  {
    decimal: 3,
    char: 'ETX',
    description: 'end of text',
    url: 'end-of-text-ETX',
  },
  {
    decimal: 4,
    char: 'EOT',
    description: 'end of transmission',
    url: 'end-of-transmission-EOT',
  },
  {
    decimal: 5,
    char: 'ENQ',
    description: 'enquiry',
    url: 'enquiry-character-ENQ',
  },
  {
    decimal: 6,
    char: 'ACK',
    description: 'acknowledge',
    url: 'acknowledge-character-ACK',
  },
  {
    decimal: 7,
    char: 'BEL',
    description: 'bell (ring)',
    url: 'bell-character-BEL',
  },
  {
    decimal: 8,
    char: 'BS',
    description: 'backspace',
    url: 'backspace-character-BS',
  },
  {
    decimal: 9,
    char: 'HT',
    description: 'horizontal tab',
    url: 'horizontal-tab-HT',
  },
  {
    decimal: 10,
    char: 'LF',
    description: 'line feed',
    url: 'line-feed-LF',
  },
  {
    decimal: 11,
    char: 'VT',
    description: 'vertical tab',
    url: 'vertical-tab-VT',
  },
  {
    decimal: 12,
    char: 'FF',
    description: 'form feed',
    url: 'form-feed-FF',
  },
  {
    decimal: 13,
    char: 'CR',
    description: 'carriage return',
    url: 'carriage-return-CR',
  },
  {
    decimal: 14,
    char: 'SO',
    description: 'shift out',
    url: 'shift-out-character-SO',
  },
  {
    decimal: 15,
    char: 'SI',
    description: 'shift in',
    url: 'shift-in-character-SI',
  },
  {
    decimal: 16,
    char: 'DLE',
    description: 'data link escape',
    url: 'data-link-escape-DLE',
  },
  {
    decimal: 17,
    char: 'DC1',
    description: 'device control 1',
    url: 'device-control-1-DC1',
  },
  {
    decimal: 18,
    char: 'DC2',
    description: 'device control 2',
    url: 'device-control-2-DC2',
  },
  {
    decimal: 19,
    char: 'DC3',
    description: 'device control 3',
    url: 'device-control-3-DC3',
  },
  {
    decimal: 20,
    char: 'DC4',
    description: 'device control 4',
    url: 'device-control-4-DC4',
  },
  {
    decimal: 21,
    char: 'NAK',
    description: 'negative acknowledge',
    url: 'negative-acknowledge-character-NAK',
  },
  {
    decimal: 22,
    char: 'SYN',
    description: 'synchronize',
    url: 'synchronize-character-SYN',
  },
  {
    decimal: 23,
    char: 'ETB',
    description: 'end transmission block',
    url: 'end-transmission-block-character-ETB',
  },
  {
    decimal: 24,
    char: 'CAN',
    description: 'cancel',
    url: 'cancel-character-CAN',
  },
  {
    decimal: 25,
    char: 'EM',
    description: 'end of medium',
    url: 'end-of-medium-character-EM',
  },
  {
    decimal: 26,
    char: 'SUB',
    description: 'substitute',
    url: 'substitute-character-SUB',
  },
  {
    decimal: 27,
    char: 'ESC',
    description: 'escape',
    url: 'escape-character-ESC',
  },
  {
    decimal: 28,
    char: 'FS',
    description: 'file separator',
    url: 'file-separator-FS',
  },
  {
    decimal: 29,
    char: 'GS',
    description: 'group separator',
    url: 'group-separator-GS',
  },
  {
    decimal: 30,
    char: 'RS',
    description: 'record separator',
    url: 'record-separator-RS',
  },
  {
    decimal: 31,
    char: 'US',
    description: 'unit separator',
    url: 'unit-separator-US',
  },
  {
    decimal: 32,
    char: 'space',
    url: 'space-character',
  },
  {
    decimal: 33,
    char: '!',
    description: 'exclamation mark',
    url: 'exclamation-mark-!',
  },
  {
    decimal: 34,
    char: '"',
    description: 'quotation mark',
    url: 'quotation-mark-"',
  },
  {
    decimal: 35,
    char: '#',
    description: 'number sign',
    url: 'number-sign',
  },
  {
    decimal: 36,
    char: '$',
    description: 'dollar sign',
    url: 'dollar-sign-$',
  },
  {
    decimal: 37,
    char: '%',
    description: 'percent sign',
    url: 'percent-sign',
  },
  {
    decimal: 38,
    char: '&',
    description: 'ampersand',
    url: 'ampersand-character-&',
  },
  {
    decimal: 39,
    char: "'",
    description: 'apostrophe',
    url: 'apostrophe-character',
  },
  {
    decimal: 40,
    char: '(',
    description: 'left parenthesis',
    url: 'left-parenthesis-(',
  },
  {
    decimal: 41,
    char: ')',
    description: 'right parenthesis',
    url: 'right-parenthesis-)',
  },
  {
    decimal: 42,
    char: '*',
    description: 'asterisk',
    url: 'asterisk-sign-*',
  },
  {
    decimal: 43,
    char: '+',
    description: 'plus sign',
    url: 'plus-sign-+',
  },
  {
    decimal: 44,
    char: ',',
    description: 'comma',
    url: 'comma',
  },
  {
    decimal: 45,
    char: '-',
    description: 'hyphen',
    url: 'hyphen',
  },
  {
    decimal: 46,
    char: '.',
    description: 'period',
    url: 'period-character',
  },
  {
    decimal: 47,
    char: '/',
    description: 'slash',
    url: 'forward-slash',
  },
  {
    decimal: 48,
    char: '0',
    description: 'digit 0',
    url: 'digit-0',
  },
  {
    decimal: 49,
    char: '1',
    description: 'digit 1',
    url: 'digit-1',
  },
  {
    decimal: 50,
    char: '2',
    description: 'digit 2',
    url: 'digit-2',
  },
  {
    decimal: 51,
    char: '3',
    description: 'digit 3',
    url: 'digit-3',
  },
  {
    decimal: 52,
    char: '4',
    description: 'digit 4',
    url: 'digit-4',
  },
  {
    decimal: 53,
    char: '5',
    description: 'digit 5',
    url: 'digit-5',
  },
  {
    decimal: 54,
    char: '6',
    description: 'digit 6',
    url: 'digit-6',
  },
  {
    decimal: 55,
    char: '7',
    description: 'digit 7',
    url: 'digit-7',
  },
  {
    decimal: 56,
    char: '8',
    description: 'digit 8',
    url: 'digit-8',
  },
  {
    decimal: 57,
    char: '9',
    description: 'digit 9',
    url: 'digit-9',
  },
  {
    decimal: 58,
    char: ':',
    description: 'colon',
    url: 'colon-character-:',
  },
  {
    decimal: 59,
    char: ';',
    description: 'semicolon',
    url: 'semicolon-character-;',
  },
  {
    decimal: 60,
    char: '<',
    description: 'less than',
    url: 'less-than-character-<',
  },
  {
    decimal: 61,
    char: '=',
    description: 'equals to',
    url: 'equals-to-character-=',
  },
  {
    decimal: 62,
    char: '>',
    description: 'greater than',
    url: 'greater-than-character->',
  },
  {
    decimal: 63,
    char: '?',
    description: 'question mark',
    url: 'question-mark-character',
  },
  {
    decimal: 64,
    char: '@',
    description: 'at sign',
    url: 'at-sign-@',
  },
  {
    decimal: 65,
    char: 'A',
    description: 'uppercase A',
    url: 'uppercase-A',
  },
  {
    decimal: 66,
    char: 'B',
    description: 'uppercase B',
    url: 'uppercase-B',
  },
  {
    decimal: 67,
    char: 'C',
    description: 'uppercase C',
    url: 'uppercase-C',
  },
  {
    decimal: 68,
    char: 'D',
    description: 'uppercase D',
    url: 'uppercase-D',
  },
  {
    decimal: 69,
    char: 'E',
    description: 'uppercase E',
    url: 'uppercase-E',
  },
  {
    decimal: 70,
    char: 'F',
    description: 'uppercase F',
    url: 'uppercase-F',
  },
  {
    decimal: 71,
    char: 'G',
    description: 'uppercase G',
    url: 'uppercase-G',
  },
  {
    decimal: 72,
    char: 'H',
    description: 'uppercase H',
    url: 'uppercase-H',
  },
  {
    decimal: 73,
    char: 'I',
    description: 'uppercase I',
    url: 'uppercase-I',
  },
  {
    decimal: 74,
    char: 'J',
    description: 'uppercase J',
    url: 'uppercase-J',
  },
  {
    decimal: 75,
    char: 'K',
    description: 'uppercase K',
    url: 'uppercase-K',
  },
  {
    decimal: 76,
    char: 'L',
    description: 'uppercase L',
    url: 'uppercase-L',
  },
  {
    decimal: 77,
    char: 'M',
    description: 'uppercase M',
    url: 'uppercase-M',
  },
  {
    decimal: 78,
    char: 'N',
    description: 'uppercase N',
    url: 'uppercase-N',
  },
  {
    decimal: 79,
    char: 'O',
    description: 'uppercase O',
    url: 'uppercase-O',
  },
  {
    decimal: 80,
    char: 'P',
    description: 'uppercase P',
    url: 'uppercase-P',
  },
  {
    decimal: 81,
    char: 'Q',
    description: 'uppercase Q',
    url: 'uppercase-Q',
  },
  {
    decimal: 82,
    char: 'R',
    description: 'uppercase R',
    url: 'uppercase-R',
  },
  {
    decimal: 83,
    char: 'S',
    description: 'uppercase S',
    url: 'uppercase-S',
  },
  {
    decimal: 84,
    char: 'T',
    description: 'uppercase T',
    url: 'uppercase-T',
  },
  {
    decimal: 85,
    char: 'U',
    description: 'uppercase U',
    url: 'uppercase-U',
  },
  {
    decimal: 86,
    char: 'V',
    description: 'uppercase V',
    url: 'uppercase-V',
  },
  {
    decimal: 87,
    char: 'W',
    description: 'uppercase W',
    url: 'uppercase-W',
  },
  {
    decimal: 88,
    char: 'X',
    description: 'uppercase X',
    url: 'uppercase-X',
  },
  {
    decimal: 89,
    char: 'Y',
    description: 'uppercase Y',
    url: 'uppercase-Y',
  },
  {
    decimal: 90,
    char: 'Z',
    description: 'uppercase Z',
    url: 'uppercase-Z',
  },
  {
    decimal: 91,
    char: '[',
    description: 'left square bracket',
    url: 'left-square-bracket-[',
  },
  {
    decimal: 92,
    char: '\\',
    description: 'backslash',
    url: 'backslash-character',
  },
  {
    decimal: 93,
    char: ']',
    description: 'right square bracket',
    description: 'right-square-bracket-]',
  },
  {
    decimal: 94,
    char: '^',
    description: 'caret',
    description: 'caret-sign-^',
  },
  {
    decimal: 95,
    char: '_',
    description: 'underscore',
    url: 'underscore-_',
  },
  {
    decimal: 96,
    char: '`',
    description: 'backtick',
    url: 'backtick-`',
  },
  {
    decimal: 97,
    char: 'a',
    description: 'lowercase a',
    url: 'lowercase-a',
  },
  {
    decimal: 98,
    char: 'b',
    description: 'lowercase b',
    url: 'lowercase-b',
  },
  {
    decimal: 99,
    char: 'c',
    description: 'lowercase c',
    url: 'lowercase-c',
  },
  {
    decimal: 100,
    char: 'd',
    description: 'lowercase d',
    url: 'lowercase-d',
  },
  {
    decimal: 101,
    char: 'e',
    description: 'lowercase e',
    url: 'lowercase-e',
  },
  {
    decimal: 102,
    char: 'f',
    description: 'lowercase f',
    url: 'lowercase-f',
  },
  {
    decimal: 103,
    char: 'g',
    description: 'lowercase g',
    url: 'lowercase-g',
  },
  {
    decimal: 104,
    char: 'h',
    description: 'lowercase h',
    url: 'lowercase-h',
  },
  {
    decimal: 105,
    char: 'i',
    description: 'lowercase i',
    url: 'lowercase-i',
  },
  {
    decimal: 106,
    char: 'j',
    description: 'lowercase j',
    url: 'lowercase-j',
  },
  {
    decimal: 107,
    char: 'k',
    description: 'lowercase k',
    url: 'lowercase-k',
  },
  {
    decimal: 108,
    char: 'l',
    description: 'lowercase l',
    url: 'lowercase-l',
  },
  {
    decimal: 109,
    char: 'm',
    description: 'lowercase m',
    url: 'lowercase-m',
  },
  {
    decimal: 110,
    char: 'n',
    description: 'lowercase n',
    url: 'lowercase-n',
  },
  {
    decimal: 111,
    char: 'o',
    description: 'lowercase o',
    url: 'lowercase-o',
  },
  {
    decimal: 112,
    char: 'p',
    description: 'lowercase p',
    url: 'lowercase-p',
  },
  {
    decimal: 113,
    char: 'q',
    description: 'lowercase q',
    url: 'lowercase-q',
  },
  {
    decimal: 114,
    char: 'r',
    description: 'lowercase r',
    url: 'lowercase-r',
  },
  {
    decimal: 115,
    char: 's',
    description: 'lowercase s',
    url: 'lowercase-s',
  },
  {
    decimal: 116,
    char: 't',
    description: 'lowercase t',
    url: 'lowercase-t',
  },
  {
    decimal: 117,
    char: 'u',
    description: 'lowercase u',
    url: 'lowercase-u',
  },
  {
    decimal: 118,
    char: 'v',
    description: 'lowercase v',
    url: 'lowercase-v',
  },
  {
    decimal: 119,
    char: 'w',
    description: 'lowercase w',
    url: 'lowercase-w',
  },
  {
    decimal: 120,
    char: 'x',
    description: 'lowercase x',
    url: 'lowercase-x',
  },
  {
    decimal: 121,
    char: 'y',
    description: 'lowercase y',
    url: 'lowercase-y',
  },
  {
    decimal: 122,
    char: 'z',
    description: 'lowercase z',
    url: 'lowercase-z',
  },
  {
    decimal: 123,
    char: '{',
    description: 'left curly brace',
    url: 'left-curly-brace-{',
  },
  {
    decimal: 124,
    char: '|',
    description: 'vertical bar',
    url: 'vertical-bar',
  },
  {
    decimal: 125,
    char: '}',
    description: 'right curly brace',
    url: 'right-curly-brace-}',
  },
  {
    decimal: 126,
    char: '~',
    description: 'tilde',
    url: 'tilde-sign',
  },
  {
    decimal: 127,
    char: 'DEL',
    description: 'delete (rubout)',
    url: 'DEL-character',
  },
]

const ExtendedAsciiChars = [
  {
    decimal: 128,
    char: '€',
    description: 'euro sign',
  },
  {
    decimal: 129,
    char: '',
    description: 'Not Used',
  },
  {
    decimal: 130,
    char: '‚',
    description: 'single low-9 quotation mark',
  },
  {
    decimal: 131,
    char: 'ƒ',
    description: 'Latin small letter f with hook',
  },
  {
    decimal: 132,
    char: '„',
    description: 'double low-9 quotation mark',
  },
  {
    decimal: 133,
    char: '…',
    description: 'horizontal ellipsis',
  },
  {
    decimal: 134,
    char: '†',
    description: 'dagger',
  },
  {
    decimal: 135,
    char: '‡',
    description: 'double dagger',
  },
  {
    decimal: 136,
    char: 'ˆ',
    description: 'modifier letter circumflex accent',
  },
  {
    decimal: 137,
    char: '‰',
    description: 'per mille sign',
  },
  {
    decimal: 138,
    char: 'Š',
    description: 'Latin capital letter S with caron',
  },
  {
    decimal: 139,
    char: '‹',
    description: 'single left-pointing angle quotation mark',
  },
  {
    decimal: 140,
    char: 'Œ',
    description: 'Latin capital ligature OE',
  },
  {
    decimal: 141,
    char: '',
    description: 'Not Used',
  },
  {
    decimal: 142,
    char: 'Ž',
    description: 'Latin capital letter Z with caron',
  },
  {
    decimal: 143,
    char: '',
    description: 'Not Used',
  },
  {
    decimal: 144,
    char: '',
    description: 'Not Used',
  },
  {
    decimal: 145,
    char: '‘',
    description: 'left single quotation mark',
  },
  {
    decimal: 146,
    char: '’',
    description: 'right single quotation mark',
  },
  {
    decimal: 147,
    char: '“',
    description: 'left double quotation mark',
  },
  {
    decimal: 148,
    char: '”',
    description: 'right double quotation mark',
  },
  {
    decimal: 149,
    char: '•',
    description: 'bullet',
  },
  {
    decimal: 150,
    char: '–',
    description: 'en dash',
  },
  {
    decimal: 151,
    char: '—',
    description: 'em dash',
  },
  {
    decimal: 152,
    char: '˜',
    description: 'small tilde',
  },
  {
    decimal: 153,
    char: '™',
    description: 'trade mark sign',
  },
  {
    decimal: 154,
    char: 'š',
    description: 'Latin small letter s with caron',
  },
  {
    decimal: 155,
    char: '›',
    description: 'single right-pointing angle quotation mark',
  },
  {
    decimal: 156,
    char: 'œ',
    description: 'Latin small ligature oe',
  },
  {
    decimal: 157,
    char: '',
    description: 'Not Used',
  },
  {
    decimal: 158,
    char: 'ž',
    description: 'Latin small letter z with caron',
  },
  {
    decimal: 159,
    char: 'Ÿ',
    description: 'Latin capital letter Y with diaeresis',
  },
  {
    decimal: 160,
    char: ' ',
    description: 'no-break space',
  },
  {
    decimal: 161,
    char: '¡',
    description: 'inverted exclamation mark',
  },
  {
    decimal: 162,
    char: '¢',
    description: 'cent sign',
  },
  {
    decimal: 163,
    char: '£',
    description: 'pound sign',
  },
  {
    decimal: 164,
    char: '¤',
    description: 'currency sign',
  },
  {
    decimal: 165,
    char: '¥',
    description: 'yen sign',
  },
  {
    decimal: 166,
    char: '¦',
    description: 'broken bar',
  },
  {
    decimal: 167,
    char: '§',
    description: 'section sign',
  },
  {
    decimal: 168,
    char: '¨',
    description: 'diaeresis',
  },
  {
    decimal: 169,
    char: '©',
    description: 'copyright sign',
  },
  {
    decimal: 170,
    char: 'ª',
    description: 'feminine ordinal indicator',
  },
  {
    decimal: 171,
    char: '«',
    description: 'left-pointing double angle quotation mark',
  },
  {
    decimal: 172,
    char: '¬',
    description: 'not sign',
  },
  {
    decimal: 173,
    char: '�­',
    description: 'soft hyphen',
  },
  {
    decimal: 174,
    char: '®',
    description: 'registered sign',
  },
  {
    decimal: 175,
    char: '¯',
    description: 'macron',
  },
  {
    decimal: 176,
    char: '°',
    description: 'degree sign',
  },
  {
    decimal: 177,
    char: '±',
    description: 'plus-minus sign',
  },
  {
    decimal: 178,
    char: '²',
    description: 'superscript two',
  },
  {
    decimal: 179,
    char: '³',
    description: 'superscript three',
  },
  {
    decimal: 180,
    char: '´',
    description: 'acute accent',
  },
  {
    decimal: 181,
    char: 'µ',
    description: 'micro sign',
  },
  {
    decimal: 182,
    char: '¶',
    description: 'pilcrow sign',
  },
  {
    decimal: 183,
    char: '·',
    description: 'middle dot',
  },
  {
    decimal: 184,
    char: '¸',
    description: 'cedilla',
  },
  {
    decimal: 185,
    char: '¹',
    description: 'superscript one',
  },
  {
    decimal: 186,
    char: 'º',
    description: 'masculine ordinal indicator',
  },
  {
    decimal: 187,
    char: '»',
    description: 'right-pointing double angle quotation mark',
  },
  {
    decimal: 188,
    char: '¼',
    description: 'vulgar fraction one quarter',
  },
  {
    decimal: 189,
    char: '½',
    description: 'vulgar fraction one half',
  },
  {
    decimal: 190,
    char: '¾',
    description: 'vulgar fraction three quarters',
  },
  {
    decimal: 191,
    char: '¿',
    description: 'inverted question mark',
  },
  {
    decimal: 192,
    char: 'À',
    description: 'Latin capital letter A with grave',
  },
  {
    decimal: 193,
    char: 'Á',
    description: 'Latin capital letter A with acute',
  },
  {
    decimal: 194,
    char: 'Â',
    description: 'Latin capital letter A with circumflex',
  },
  {
    decimal: 195,
    char: 'Ã',
    description: 'Latin capital letter A with tilde',
  },
  {
    decimal: 196,
    char: 'Ä',
    description: 'Latin capital letter A with diaeresis',
  },
  {
    decimal: 197,
    char: 'Å',
    description: 'Latin capital letter A with ring above',
  },
  {
    decimal: 198,
    char: 'Æ',
    description: 'Latin capital letter AE',
  },
  {
    decimal: 199,
    char: 'Ç',
    description: 'Latin capital letter C with cedilla',
  },
  {
    decimal: 200,
    char: 'È',
    description: 'Latin capital letter E with grave',
  },
  {
    decimal: 201,
    char: 'É',
    description: 'Latin capital letter E with acute',
  },
  {
    decimal: 202,
    char: 'Ê',
    description: 'Latin capital letter E with circumflex',
  },
  {
    decimal: 203,
    char: 'Ë',
    description: 'Latin capital letter E with diaeresis',
  },
  {
    decimal: 204,
    char: 'Ì',
    description: 'Latin capital letter I with grave',
  },
  {
    decimal: 205,
    char: 'Í',
    description: 'Latin capital letter I with acute',
  },
  {
    decimal: 206,
    char: 'Î',
    description: 'Latin capital letter I with circumflex',
  },
  {
    decimal: 207,
    char: 'Ï',
    description: 'Latin capital letter I with diaeresis',
  },
  {
    decimal: 208,
    char: 'Ð',
    description: 'Latin capital letter Eth',
  },
  {
    decimal: 209,
    char: 'Ñ',
    description: 'Latin capital letter N with tilde',
  },
  {
    decimal: 210,
    char: 'Ò',
    description: 'Latin capital letter O with grave',
  },
  {
    decimal: 211,
    char: 'Ó',
    description: 'Latin capital letter O with acute',
  },
  {
    decimal: 212,
    char: 'Ô',
    description: 'Latin capital letter O with circumflex',
  },
  {
    decimal: 213,
    char: 'Õ',
    description: 'Latin capital letter O with tilde',
  },
  {
    decimal: 214,
    char: 'Ö',
    description: 'Latin capital letter O with diaeresis',
  },
  {
    decimal: 215,
    char: '×',
    description: 'multiplication sign',
  },
  {
    decimal: 216,
    char: 'Ø',
    description: 'Latin capital letter O with stroke',
  },
  {
    decimal: 217,
    char: 'Ù',
    description: 'Latin capital letter U with grave',
  },
  {
    decimal: 218,
    char: 'Ú',
    description: 'Latin capital letter U with acute',
  },
  {
    decimal: 219,
    char: 'Û',
    description: 'Latin capital letter U with circumflex',
  },
  {
    decimal: 220,
    char: 'Ü',
    description: 'Latin capital letter U with diaeresis',
  },
  {
    decimal: 221,
    char: 'Ý',
    description: 'Latin capital letter Y with acute',
  },
  {
    decimal: 222,
    char: 'Þ',
    description: 'Latin capital letter Thorn',
  },
  {
    decimal: 223,
    char: 'ß',
    description: 'Latin small letter sharp s',
  },
  {
    decimal: 224,
    char: 'à',
    description: 'Latin small letter a with grave',
  },
  {
    decimal: 225,
    char: 'á',
    description: 'Latin small letter a with acute',
  },
  {
    decimal: 226,
    char: 'â',
    description: 'Latin small letter a with circumflex',
  },
  {
    decimal: 227,
    char: 'ã',
    description: 'Latin small letter a with tilde',
  },
  {
    decimal: 228,
    char: 'ä',
    description: 'Latin small letter a with diaeresis',
  },
  {
    decimal: 229,
    char: 'å',
    description: 'Latin small letter a with ring above',
  },
  {
    decimal: 230,
    char: 'æ',
    description: 'Latin small letter ae',
  },
  {
    decimal: 231,
    char: 'ç',
    description: 'Latin small letter c with cedilla',
  },
  {
    decimal: 232,
    char: 'è',
    description: 'Latin small letter e with grave',
  },
  {
    decimal: 233,
    char: 'é',
    description: 'Latin small letter e with acute',
  },
  {
    decimal: 234,
    char: 'ê',
    description: 'Latin small letter e with circumflex',
  },
  {
    decimal: 235,
    char: 'ë',
    description: 'Latin small letter e with diaeresis',
  },
  {
    decimal: 236,
    char: 'ì',
    description: 'Latin small letter i with grave',
  },
  {
    decimal: 237,
    char: 'í',
    description: 'Latin small letter i with acute',
  },
  {
    decimal: 238,
    char: 'î',
    description: 'Latin small letter i with circumflex',
  },
  {
    decimal: 239,
    char: 'ï',
    description: 'Latin small letter i with diaeresis',
  },
  {
    decimal: 240,
    char: 'ð',
    description: 'Latin small letter eth',
  },
  {
    decimal: 241,
    char: 'ñ',
    description: 'Latin small letter n with tilde',
  },
  {
    decimal: 242,
    char: 'ò',
    description: 'Latin small letter o with grave',
  },
  {
    decimal: 243,
    char: 'ó',
    description: 'Latin small letter o with acute',
  },
  {
    decimal: 244,
    char: 'ô',
    description: 'Latin small letter o with circumflex',
  },
  {
    decimal: 245,
    char: 'õ',
    description: 'Latin small letter o with tilde',
  },
  {
    decimal: 246,
    char: 'ö',
    description: 'Latin small letter o with diaeresis',
  },
  {
    decimal: 247,
    char: '÷',
    description: 'division sign',
  },
  {
    decimal: 248,
    char: 'ø',
    description: 'Latin small letter o with stroke',
  },
  {
    decimal: 249,
    char: 'ù',
    description: 'Latin small letter u with grave',
  },
  {
    decimal: 250,
    char: 'ú',
    description: 'Latin small letter u with acute',
  },
  {
    decimal: 251,
    char: 'û',
    description: 'Latin small letter with circumflex',
  },
  {
    decimal: 252,
    char: 'ü',
    description: 'Latin small letter u with diaeresis',
  },
  {
    decimal: 253,
    char: 'ý',
    description: 'Latin small letter y with acute',
  },
  {
    decimal: 254,
    char: 'þ',
    description: 'Latin small letter thorn',
  },
  {
    decimal: 255,
    char: 'ÿ',
    description: 'Latin small letter y with diaeresis',
  },
]

exports.AsciiChars = AsciiChars
exports.ExtendedAsciiChars = ExtendedAsciiChars
