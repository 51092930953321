import React from 'react'
import Container from '../Container'
import './Converter.scss'

class Converter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      output: '',
      padding: true,
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    if (window.innerWidth > 992) {
      this.inputRef.current.focus()
    }
  }

  handlePaddingChange = (event) => {
    this.setState(
      {
        padding: !this.state.padding,
      },
      () => {
        if (this.inputRef.current && this.inputRef.current.value) {
          this.handleInputChange(this.inputRef.current.value)
        }
      }
    )
  }

  handleInputChange = (value) => {
    let output = ''
    if (this.props.to) {
      const toBase = parseInt(this.props.to)
      for (var i = 0; i < value.length; i++) {
        output += value.charCodeAt(i).toString(toBase)
        if (this.state.padding && i < value.length - 1) {
          output += ' '
        }
      }
    }
    this.setState({
      output: output,
    })
  }

  selectArea = (event) => {
    if (event.target.value) {
      event.target.select()
    }
  }

  render() {
    return (
      <div className="converter">
        <Container>
          <div className="converter-form">
            <h1 className="converter-heading">{`${this.props.fromtext} to ${this.props.totext} Converter`}</h1>
            <form id="ascii-converter-form">
              <div className="form-item">
                <textarea
                  className="form-control input-area"
                  placeholder={`${this.props.fromtext} input...`}
                  aria-label="InputString"
                  ref={this.inputRef}
                  onChange={(event) =>
                    this.handleInputChange(event.target.value)
                  }
                />

                <span>
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.padding}
                      onChange={this.handlePaddingChange}
                    />{' '}
                    Padding
                  </label>
                </span>
              </div>
              <div className="form-item">
                <textarea
                  className="form-control output-area"
                  placeholder={`${this.props.totext} output...`}
                  readOnly
                  onFocus={this.selectArea}
                  aria-label="Output"
                  value={this.state.output}
                />
              </div>
              <div className="info">
                <span className="bug">
                  Found a bug?{' '}
                  <a href="mailto:rajeevhub@gmail.com?subject=Bug in AsciiTable">
                    report!
                  </a>
                </span>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

export default Converter
